import React from "react";
import { NotionRenderer } from "react-notion-x";
import notionPost from "./gettingstartedRecordMap.json";
import styled from "@emotion/styled";
import { LoomVideoEmbed } from "../components/common/LoomVideoEmbed";
import { StaticImage } from "gatsby-plugin-image";
const NotionContainerDiv = styled.div`
  & .notion-viewport {
    display: none;
  }
`;

export const GettingStartedBlogPost: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl mx-auto">
          <article>
            <header>
              <div className="mb-6 text-center">
                <span className="text-base md:text-lg">
                  <span className="mr-2 text-purple-500 cursor-default">
                    GUIDE
                  </span>
                  <span className="text-blueGray-800">20 Jan, 2023</span>
                </span>
                <h2 className="mt-4 text-4xl font-bold md:text-5xl font-heading">
                  Getting Started with Zorbi
                </h2>
              </div>
              <div className="flex justify-center mb-8">
                <StaticImage
                  className="object-cover w-12 h-12 rounded-full"
                  src={"../images/blog/making-good-flashcards/sukhans.jpg"}
                  placeholder="blurred"
                  alt="sukhans"
                />
                <div className="pl-4">
                  <p className="mb-1 text-blueGray-500">Sukhans Asrani</p>
                  <p className="text-xs font-semibold text-purple-600">
                    Founder @ Zorbi
                  </p>
                </div>
              </div>
            </header>
            <NotionContainerDiv>
              <NotionRenderer recordMap={notionPost as any} />
            </NotionContainerDiv>
            <iframe
              className="w-full max-w-2xl mt-4 "
              height="315"
              src="https://www.youtube.com/embed/iyhnTN70s8c"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </article>
        </div>
      </div>
    </section>
  );
};
