import React from "react";
import SEO from "../components/common/layout/seo";
import Layout from "../components/common/layout/layout";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";
import { GettingStartedBlogPost } from "../blogPosts/GettingStartedBlogPost";
import { gettingStarted } from "../utils/seoText";

export const GettingStartedPage = () => {
  return (
    <Layout>
      <SEO {...gettingStarted} />
      <Navigation />
      <GettingStartedBlogPost />
      <Footer />
    </Layout>
  );
};

export default GettingStartedPage;
